<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterList">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.title"
                id="filter_title"
                :label="$t('car.filter.title')"
              ></app-input>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.type"
                :options="carTypeValues"
                :label="$t('car.filter.type')"
                id="filter_type"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.brand"
                id="filter_brand"
                :label="$t('car.filter.brand')"
              ></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.model"
                id="filter_model"
                :label="$t('car.filter.model')"
              ></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.id"
                :label="$t('car.filter.id')"
              ></app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success pull-right m-t-30"
                data-test="search"
                @click="filterList"
              >
                Search
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import CarTypeMixin from '../mixins/valueObject/CarTypeMixin'
import CarFilterModel from '../../model/CarFilterModel'

export default {
  name: 'CarFilter',
  mixins: [CarTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(CarFilterModel)
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterList () {
      this.$store.commit('car/setPage', 1)
      this.$store.commit('car/setFilter', this.filter)
      this.$store.dispatch('car/fetch')
    },
    resetFilter () {
      this.$store.commit('car/setPage', 1)
      this.$store.commit('car/resetFilter')
      this.$store.dispatch('car/fetch')
    }
  },
  created () {
    this.filter = this.$store.getters['car/filter']
  }
}
</script>

<style lang="scss">

</style>
